.mc__commheader {
    display: flex;
    flex-direction: column;
    background-image: url("../../../assets/images/front.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment:fixed;
    color: white;
    justify-content: center;
    align-items: center;
}

.mc__commheader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 15vw 0;
    max-width: 70rem;
}

.mc__commheader-container h1 {
    font-size: 60px;
    padding-bottom: 0;
}

.mc__commheader-container p {
    margin-top: 0;
    font-size: 2.5vw;
    font-weight: 500;
}

@media screen and (max-width: 414px) {
    .mc__commheader {
        background-position: left;
    }
    .mc__commheader-container {
        padding: 8rem 2rem;
        padding-bottom: 4rem;
        max-width: 100%;
    }

    .mc__commheader-container h1 {
        font-size: 35px;
    }
    
}