.mc__commteam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #58cc02;
    margin: 0 0;
    padding: 9vw 9vw;
    color: white;
}

.mc__commteam-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.mc__commteam-container h1 {
    font-size: 3vw;
}

.mc__commteam-container_content p {
    font-size: 1.5vw;
}
.mc__commteam-container_content {
    padding-top: 0vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.mc__commteam-container_content div {
    display: flex;
    flex-direction: column;
}

.mc__commteam-container_content img {
    width: 80%;
}

.mc__commteam-container_content-left {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mc__commteam-container_content-right {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mc__commteam-container_content-text {
    max-width: 35vw;
}

.mc__commteam-container-img {
    display: none;
}
@media screen and (max-width: 414px) {
    .mc__commteam {
        padding: 3rem 2rem;
        padding-top: 4rem;
    }
    
    .mc__commteam-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    
    .mc__commteam-container h1 {
        font-size: 25px;
    }
    
    .mc__commteam-container_content p {
        font-size: 16px;
    }
    
    .mc__commteam-container_content-left {
        display: none;
        width: 0%;
    }
    
    .mc__commteam-container_content-right {
        display: none;
        width: 0%;
    }
    .mc__commteam-container_content-left img, .mc__commteam-container_content-right img {
        display: none;
        width: 0%;
    }
    .mc__commteam-container_content-text {
        width: 100%;
        max-width: 100%;
    }
    .mc__commteam-container-img {
        display: flex;
        position: relative;
        bottom: 1.5rem;
        margin: 0rem -1rem;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0rem;
    }
    .mc__commteam-container-img img {
        width: 4rem;
    }
    .mc__commteam-container-img_tobble {
        position: relative;
    }
}