.mc__server {
    display: flex;
    padding: 0rem 0rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    margin: 0 0;
    background-color: rgb(41, 41, 41);
}

.mc__server-container_online {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mc__server-container_online svg {
    padding-left: 3px;
}