.mc__error {
    padding: 30vw 0rem;
    text-align: center;
    font-size: 2vw;
    background-image: url("../../../assets/images/sadfinn.png");
    background-size: 18vw;
    background-position: center;
    color: white;

}

.mc__error h1 {
    margin-top: -3vw;
}

@media screen and (max-width: 414px) {
    .mc__error {
        padding: 20rem 2rem;
    }
    .mc__error h1 {
        font-size: 35px;
    }
    .mc__error i {
        font-size: 18px;
    }
}