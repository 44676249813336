.mc__commbar {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8vw;
    padding: 0rem 6rem;
    background-color: rgb(88, 204, 2) ;
    border-bottom: #58a700 solid 5px;
    font-weight: bolder;
    justify-content: center;
    text-align: center;
    scroll-behavior: smooth;
}

.mc__commbar a {
    padding: 1rem 0rem;
    padding-top: 1.3rem;
    font-size: 20px;
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 414px) {
    .mc__commbar {
        gap: 3rem;
        padding: 0 0;
    }
    .mc__commbar a {
        padding: 1rem 0px;
        font-size: 16px;
    }
}
