.mc__footer {
    background: black;
    margin-bottom: -2rem;
    left: 0;
    right: 0;
    bottom: 0;
}

.mc__footer-container {
    padding: 8vw 18vw;
    /* max-width: 60vw; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 5vw;
    justify-content: space-around; 
}

.mc__footer-container div {
    display: flex;
    flex-direction: column;
    min-width: 10rem;
}

.mc__footer-container h2 {
    font-size: 20px;
    color: rgb(238, 238, 238);
}

.mc__footer-container a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 23px;
    padding: 1rem 0rem;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .mc__footer-container {
        column-gap: 3vw;
        padding: 5rem 10vw;
    }    

    .mc__footer-container div {
        min-width: 8vw;
    }
    .mc__footer-container a {
        font-size: 1.5vw;
    }
    .mc__footer-container h2 {
        font-size: 2vw;
    }
}

@media screen and (max-width: 414px) {
    .mc__footer-container {
        padding: 4rem 4rem;
        grid-template-columns: auto auto;
        row-gap: 2rem;
        column-gap: normal;
        justify-content: space-between;
    }
    .mc__footer-container div {
        min-width: 0vw;
    }
    .mc__footer-container a {
        font-size: 17px;
    }
    .mc__footer-container h2 {
        font-size: 15px;
    }
}

