.mc__teampage {
    padding: 10rem 15vw;
    font-family: var(--font-family);
    max-width: 90vw;
    background-color: white;
}

.mc__teampage-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mc__teampage-info h1 {
    text-align: center;
    font-size: 2.5vw;
}

.mc__teampage-info i {
    font-size: 1.4vw;
}

.mc__teampage-info_bar {
    width: 100%;
    height: 4px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 2vw;
}

.mc__teampage-join {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.mc__teampage-join a {
    font-weight: bold;
}
.mc__teampage-join_grey a {
    color: white
}

.mc__teampage-join_white a {
    color: rgb(49, 49, 49);
}

.mc__teampage-join h1 {
    margin-bottom: 1rem;
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 1.7vw;
}

.mc__teampage-join h2 {
    margin-bottom: 1rem;
}

.mc__teampage-join_grey,
.mc__teampage-join_white {
    margin: 0rem 0;
    border-radius: 0.1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2vw;
}

.mc__teampage-join_grey {
    color: white;
    background: rgb(197, 197, 197);
}

.mc__teampage-join_grey h2,
.mc__teampage-join_white h2 {
    font-size: 1vw;
}

.mc__teampage-join_grey p,
.mc__teampage-join_white p {
    font-size: 0.8vw;
}

.mc__teampage-join_grey a,
.mc__teampage-join_white a {
    font-size: 0.8vw;
}

.mc__teampage-join_grey_apply,
.mc__teampage-join_white_apply {
    text-decoration: underline;
    margin-right: 1.2vw;
}

@media screen and (max-width: 414px) {
    .mc__teampage {
        padding: 7rem 2rem;
    }
    .mc__teampage-info h1{
        font-size: 23px;
        margin-bottom: 0;
    }

    .mc__teampage-info_bar {
        height: 3px;
        box-shadow: 0 2px 2px rgba(0,0,0,0.25);
    }
    .mc__teampage-info i {
        font-size: 14px;
    }

    .mc__teampage-join h1 {
        margin-bottom: 0.8rem;
        font-size: 18px;
    }
    
    .mc__teampage-join_grey,
    .mc__teampage-join_white {
        padding: 0rem 1rem;
    }
    
    .mc__teampage-join_grey h2,
    .mc__teampage-join_white h2 {
        font-size: 11px;
        margin-bottom: 0rem;
    }
    
    .mc__teampage-join_grey p,
    .mc__teampage-join_white p {
        font-size: 10px;
    }
    
    .mc__teampage-join_grey a,
    .mc__teampage-join_white a {
        font-size: 10px;
    }
    
    .mc__teampage-join_grey_apply,
    .mc__teampage-join_white_apply {
        text-decoration: underline;
        margin-right: 1.2vw;
    }
}

