.mc__how {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
}

.mc__how-container {
    padding: 8vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50vw;
}

.mc__how-container h1 {
    font-size: 40px;
}

.mc__how-container p {
    font-size: 22px;
}

.mc__how-container ol {
    font-size: 22px;
}

.mc__how-container_notice {
    margin: 2rem 0;
    padding: 1rem 0;
    padding-left: 2rem;
    background-color: whitesmoke;
    border-left: 6px solid rgb(255, 208, 0);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

@media screen and (max-width: 414px) {
    .mc__how-container {
        max-width: 100%;
        padding: 7rem 2rem;
    }
    .mc__how-container h1 {
        font-size: 30px;
        margin-bottom: 0;
    }
    .mc__how-container p {
        font-size: 20px;
    }
    .mc__how-container ol {
        font-size: 20px;
        padding: 0 1rem;
        margin: 0;
    }
    .mc__how-container li {
        padding: 0.5rem 0;
    }
    .mc__how-container_notice {
        margin: 1rem 0;
        padding: 0.5rem 0;
        padding-left: 1rem;
        background-color: whitesmoke;
        border-left: 5px solid rgb(255, 208, 0);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0rem;
    }
}