.mc__commvideo-container {
    height: 30vw;
    width: 18vw;
    padding: 0rem 0rem;
    display: flex;         
    flex-direction: column;

}

.mc__commvideo-container_image {
    width: 100%;
    margin-bottom: 1rem;
}

.mc__commvideo-container_image iframe {
    height: 28vw;
    width: 16vw;
}

@media screen and (max-width: 414px) {
    .mc__commvideo-container {
        height: 13rem;
        width: 7rem
    
    }
    .mc__commvideo-container_image iframe {
        height: 12rem;
        width: 6rem;
    }
}
