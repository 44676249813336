.mc__header {
    display: flex;
    flex-direction: row;
    padding: 12vw 0;
    justify-content: center;
    background-image: url('../../../assets/images/mythos.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment:fixed;
    text-align: center;
}

.mc__header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    max-width: 60vw;
}

.mc__header-container h1 {
    font-size: 4vw;   
    margin: 0;
}

.mc__header-container p {
    font-size: 2.5vw;
    margin: 0;
    font-style: italic;
}

.mc__header-container_button {
    padding-top: 2rem;
    display: none;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.mc__header-container_button button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    background-color: #58cc02;
    box-shadow: 0 5px 0 #58a700;
    border: none;
    padding: 0.8rem 1.5rem;
    padding-top: 0.3rem;
    /* border-radius: 20px; */
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 100%;
}

.mc__header-container_button svg {
    display: flex;
    padding-top: 1.1rem;
    padding-left: 0.5rem;
    vertical-align: middle;
}

.mc__header-container button:active {
    box-shadow: none;
    transform: translateY(5px);
}

.mc__header-container_online {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.mc__header-container_online div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: white;
    font-weight: bold;
}

.mc__header-container_online p {
    font-style: normal;
    padding: 0 0;
    margin: 0 0;
    font-size: 2.3vw;
}
.mc__header-container_online svg {
    font-size: 2vw;
    padding-left: 0.5rem;
}



@media screen and (max-width: 600px) {
    .mc__header {
        margin-top: 0rem;
        background-image: url('../../../assets/images/mythos.png');
        background-position: left;
    }
    .mc__header-container {
        padding: 6rem 2rem;
        text-align: left;
        max-width: 100%;
    }
    .mc__header-container h1 {
        font-size: 45px;
    }
    .mc__header-container p {
        font-size: 25px;
        margin-top: 1rem;
    }
    .mc__header-container_button {
        display: flex;
        flex-direction: row;
    }
    .mc__header-container_button p {
        font-style: normal;
        font-size: 23px;
    }

    .mc__header-container_online {
        padding-top: 1rem;
    }
    .mc__header-container_online svg {
        font-size: 25px;
        display: flex;
        padding-top: 10px;
    }
}