.mc__commdiscord {
    background-color: rgb(24, 24, 24);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10vw 0rem;
    margin: 0 0;
}

.mc__commdiscord-container {
    max-width: 70vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mc__commdiscord-container h1 {
    font-size: 2.8vw;
}

.mc__commdiscord-container p {
    font-size: 1.5vw;
    margin: 1rem 0;
}

.mc__commdiscord img {
    padding-left: 5vw;
    height: 25vw;
}

.mc__commdiscord-container button {
    cursor: pointer;
    background-color: #58cc02;
    box-shadow: 0 5px 0 #58a700;
    border: none;
    /* border-radius: 20px; */
    color: white;
    font-weight: bolder;
    font-size: 1.3vw;
    margin-top: 1vw;
    padding: 0.9vw 1.5vw;
    padding-top: 1.2vw;
    float: right;
}

.mc__commdiscord-container button:active {
    box-shadow: none;
    transform: translateY(5px);
}

.mc__commdiscord-container_heading {
    display: flex;
    flex-direction: row;
}

.mc__commdiscord-container_heading img {
    display: none;
    position: relative;
    right: 2rem;
    z-index: 0;
}

@media screen and (max-width: 414px) {
    .mc__commdiscord {
        padding: 5rem 2rem;
    }
    .mc__commdiscord-container {
        max-width: 100%;
    }
    .mc__commdiscord-container h1 {
        font-size: 25px;
    }
    .mc__commdiscord-container p {
        font-size: 16px;
    }
    .mc__commdiscord-container_img img {
        display: none;
    }
    .mc__commdiscord-container button {
        cursor: pointer;
        background-color: #58cc02;
        box-shadow: 0 5px 0 #58a700;
        border: none;
        /* border-radius: 20px; */
        color: white;
        font-weight: bolder;
        font-size: 16px;
        margin-top: 1rem;
        padding: 0.7rem 1.5rem;
        padding-top: 1rem;
        width: 100%;
    }

    .mc__commdiscord-container_heading img {
        display: flex;
    }

}