.mc__team {
    display: flex;
    margin: 0 0;
    flex-direction: row;
    background-color: white;
    justify-content: center;
}

.mc__team-container {
    display: flex;
    flex-direction: row;
    width: 70vw;
    padding: 8vw 4vw;
}

.mc__team-container_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.mc__team-container_content h1 {
    font-weight: 500;
    line-height: 120%;
    font-size: 2.5vw;
    margin: 0;
}

.mc__team-container_content p {
    font-size: 1.3vw;
    margin-bottom: 0;
}

.mc__team-container_content button {
    cursor: pointer;
    background-color: #58cc02;
    box-shadow: 0 0.3vw 0 #58a700;
    border: none;
    padding: 0.8vw 1.5vw;
    padding-top: 1vw;
    color: white;
    font-weight: bolder;
    font-size: 1.3vw;
    width: 100%;
    margin-top: 2rem;
}

.mc__team-container_content button:active {
    box-shadow: none;
    transform: translateY(5px);
}

.mc__team-container_img {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-right: 8vw;
    width: 50%;
}

.mc__team-container_img img {
    border-radius: 1vw;
    height: 100%;
}

.mc__team-container_img2 {
    display: none;
}

@media screen and (max-width: 414px) {
    .mc__team-container {
        flex-direction: column;
        width: 100%;
        padding: 6rem 3rem;
    }
    .mc__team-container_content {
        width: 100%;
    }
    .mc__team-container_content h1 {
        font-size: 35px;
    }
    .mc__team-container_content p {
        font-size: 20px;
    }
    .mc__team-container_img {
        display: flex;
        padding-right: 0;
        padding-bottom: 2rem;
        width: 100%;
    }
    .mc__team-container_img img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }
    .mc__team-container_img1 {
        display: none;
    }
    .mc__team-container_img2 {
        display: flex;
    }
    .mc__team-container_content button {
        cursor: pointer;
        background-color: #58cc02;
        box-shadow: 0 0.3rem 0 #58a700;
        border: none;
        padding: 1rem 1.5rem;
        padding-top: 1.3rem;
        color: white;
        font-weight: bolder;
        font-size: 20px;
        width: 100%;
    }

}