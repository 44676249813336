.mc__navbar {
    display: flex;
    padding: 1.8rem 4rem;
    background-color: var(--background-color);
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
}

.mc__navbar-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mc__navbar-heading a {
    display: flex;
}

.mc__navbar-heading img { 
    height: 1.4rem;
}

.mc__navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mc__navbar-links div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mc__navbar-links a {
    color: white;
    text-align: center;
    padding: 0rem 1.5rem;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
}

.mc__navbar-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mc__navbar-right_play {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
}

.mc__navbar-right_play button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: white;
    display: flex;
    flex-direction: row;
}

.mc__navbar-right_play svg {
    display: flex;
    flex-direction: row;
    padding-left: 0.5rem;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.mc__navbar-right_discord {
    display: flex;
    flex-direction: column;
}

.mc__navbar-right_discord img {
    width: 30px;
    display: flex;
}

.mc__navbar-right_menu {
    display: none;
    padding-left: 2rem;
    margin-right: -1rem;
}

.mc__navbar-right_menu-container {
    display: block;
    flex-direction: column;
    position: fixed;
    top: 5.3rem;
    left: 0;
    right: 0;
    background-color: var(--container-color);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 3px 7.5px rgba(0, 0 ,0, 0.3);
}

.mc__navbar-right_menu-container div {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.mc__navbar-right_menu-container div:hover {
    background-color: var(--container-hover-color);
}

/* .mc__navbar-right_online {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
}
.mc__navbar-right_online div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-weight: bold;
}

.mc__navbar-right_online p {
    padding: 0 0;
    margin: 0 0;
    font-size: 18px;
}
.mc__navbar-right_online div svg {
    padding: 0rem 3px;
} */


@media screen and (max-width: 1200px) {
    .mc__navbar-links {
        display: none;
    }
    .mc__navbar-right_menu {
        display: flex;
    }
}

@media screen and (max-width: 414px) {
    .mc__navbar {
        left: 0;
        right: 0;
        top: 0;
        padding: 1.8rem 2rem;
    }

    .mc__navbar-heading img {
        height: 1.1rem;
    }

    .mc__navbar-right_play {
        display: none;
    }

    .mc__navbar-right_menu {
        margin-right: 0;
        padding-left: 1.5rem;
    }

    .mc__navbar-right_menu-container {
        top: 5.2rem;
    }
}