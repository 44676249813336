.mc__commcontent {
    padding: 5vw 0rem;
    background-color: rgb(255, 255, 255);
    padding-bottom: 10vw;
}

.mc__commcontent-container_heading h1 {
    text-align: center;
    font-size: 2.8vw;
    padding-bottom: 3vw;
}

.mc__commcontent-container_videos {
    justify-content: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 1rem;

}

@media screen and (max-width: 414px) {
    .mc__commcontent {
        padding: 1rem 2rem;
    }

    .mc__commcontent-container_heading h1 {
        font-size: 25px;
    }

    .mc__commcontent-container_videos {
        justify-content: center;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 2rem;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }
}